/**
 * Firebase Login
 * Vuely comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
//import router from '../router'

// Initialize Firebase 
const firebaseConfig = {
  apiKey: "AIzaSyBqIzQ4qP_8KuERvBlmo8uDKqTVGHp9Bwg",
  authDomain: "shaked-web.firebaseapp.com",
  databaseURL: "https://shaked-web.firebaseio.com",
  projectId: "shaked-web",
  storageBucket: "shaked-web.appspot.com",
  messagingSenderId: "506030106311",
  appId: "1:506030106311:web:e8c1a97876f62c6c8ec0e2",
  measurementId: "G-YY2P5C2SE2"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const firestore = firebase.firestore();
const { Timestamp } = firebase.firestore


// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { TimeStamp, GeoPoint } = firebase.firestore
export { TimeStamp, GeoPoint }




const moviesCollection = firestore.collection('Movies')

const moviesCurrent = firestore.collection('Movies').where('last', '==', true)
const usersCollection = firestore.collection('users')


export {
    auth,
    googleAuthProvider,
    firebase,
    firestore,
    Timestamp,
    usersCollection,
    moviesCollection,
    moviesCurrent
};
