<template>
  <div id="app">
    <SiteNav></SiteNav>
    <router-view> </router-view>
    <SiteFooter></SiteFooter>
    <!-- ommited -->
    <SocialChat icon class="whatsapp-chat" :attendants="attendants" dir="rtl">
      <p slot="header">יש לך שאלה לגבי המוסדות? שאלה בהלכה? נשמח לעזור</p>

      <small slot="footer"> אורחות יצחק</small>
    </SocialChat>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SiteNav from "@/components/SiteNav";
import SiteFooter from "@/components/SiteFooter";
import { SocialChat } from "vue-social-chat";

export default {
  components: {
    SiteNav,
    SiteFooter,
    SocialChat,
  },
  computed: {
    ...mapState({userProfile:["userProfile"],amount:'amount'}),
    
    showNav() {
      return Object.keys(this.userProfile).length > 1;
    },
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "אורחות יצחק";
    },
  },
  data: () => ({
    attendants: [
      {
        app: "whatsapp",
        label: "עזרה בכל שאלה",
        name: "אורחות יצחק בוואצאפ",
        number: "97298335994",
        avatar: {
          src: "/static/logo.png", //'https://orchot.co.il/up/logo/79561_t.gif',
          alt: "אורחות יצחק",
        },
      },
      // ...
    ],
  }),
};
</script>
<style>
.whatsapp-chat {
  --whatsapp: #46c056;
  --vsc-bg-header: var(--whatsapp);
  --vsc-bg-button: var(--whatsapp);
  --vsc-outline-color: var(--whatsapp);
}
</style>
 