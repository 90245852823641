import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=5acdd70a&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5acdd70a",
  null
  
)

export default component.exports