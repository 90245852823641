<template>
  <header>
    <section>
        <footer class="position-relative" id="footer-main">
    <div class="footer pt-lg-7 footer-dark bg-dark">
       
        <!-- Footer -->
        <div class="container pt-4 text-lg-right">
            <div class="row">
                <div class="col-lg-4 mb-5 mb-lg-0 ml-lg-auto">
                    <!-- Theme's logo 
                    <a href="index.html">
                        <img alt="Image placeholder" src="assets/img/brand/logo.svg" id="footer-logo">
                    </a>-->
                    <!-- Webpixels' mission -->
                    <p class="mt-4 text-sm opacity-8 pr-lg-4">
                        אתר מוסדות אורחות יצחק כאן בשבילכם כדי שתוכלו לקחת חלק בשלל הפעילויות השיעורים והאירועים של המוסדות</p>
                    <!-- Social -->
                    <ul class="nav mt-4">
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.youtube.com/channel/UCUOouQEqC_a8ZXSpxrWuJ-w/videos" target="_blank">
                                <youtube-icon ></youtube-icon> 
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.facebook.com/orcot.co.il" target="_blank">
                               <facebook-icon ></facebook-icon> 
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-2 col-6 col-sm-4  mb-5 mb-lg-0">
                    <h6 class="heading mb-3">מוסדות</h6>
                    <ul class="list-unstyled">
                       <li>  <router-link  to="/synagogue">בית כנסת</router-link></li>
                           <li>  <router-link to="/talmod"> תלמוד תורה</router-link></li>
                           <li>  <router-link to="/yeshiva">ישיבות</router-link></li>
                     
                    </ul>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
                    <h6 class="heading mb-3">חינוך</h6>
                    <ul class="list-unstyled">
                        <li><a href="#">מעון</a></li>
                        <li><a href="#">גן ילדים</a></li>
                        <li><a href="#">תלמוד תורה</a></li>
                    </ul>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
                    <h6 class="heading mb-3">אחר</h6>
                    <ul class="list-unstyled">
                       <li>  <router-link  to="/community">קהילה</router-link></li>
                       <li>  <router-link  to="/contactus">צור קשר</router-link></li>
                    </ul>
                </div>
            </div>
            <hr class="divider divider-fade divider-dark my-4">
            <div class="row align-items-center justify-content-md-between pb-4 text-lg-right">
                <div class="col-md-6">
                    <div class="copyright text-sm font-weight-bold text-right ">
                      <router-link  to="/" class="font-weight-bold" > אורחות יצחק</router-link>. עמותה
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                        <li class="nav-item">
                            <a class="nav-link" href="#">
                                תנאי שימוש
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">
                                פרטיות
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">
                                Cookies
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
    </section>
  </header>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>