import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false,
      title:'אורחות יצחק'
    }
  },
  {
    path: '/talmod',
    name: 'Talmod',
    component: () => import( /* webpackChunkName: "home" */ '../views/Talmod.vue')
  },
  {
    path: '/movies',
    name: 'Movies',
    component: () => import( /* webpackChunkName: "home" */ '../views/Movies.vue')
  },
  {
    path: '/contactus',
    name: 'Contactus',
    component: () => import( /* webpackChunkName: "home" */ '../views/Contactus.vue')
  },
  
  {
    path: '/community',
    name: 'Community',
    component: () => import( /* webpackChunkName: "home" */ '../views/Community.vue')
  },{
    path: '/holidays',
    name: 'Holidays',
    component: () => import( /* webpackChunkName: "home" */ '../views/Holidays.vue')
  },{
    path: '/midrasha',
    name: 'Midrasha',
    component: () => import( /* webpackChunkName: "home" */ '../views/Midrasha.vue')
  },{
    path: '/synagogue',
    name: 'Synagogue',
    component: () => import( /* webpackChunkName: "home" */ '../views/Synagogue.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import( /* webpackChunkName: "home" */ '../views/test.vue')
  },
  {
    path: '/yeshiva',
    name: 'Yeshiva',
    component: () => import( /* webpackChunkName: "home" */ '../views/Yeshiva.vue')
  },
  {
    path: '/yeshiva2',
    name: 'Yeshiva2',
    component: () => import( /* webpackChunkName: "home" */ '../views/Yeshiva2.vue')
  },
  {
    path: '/joinus',
    name: 'Joinus',
    component: () => import( /* webpackChunkName: "home" */ '../views/Joinus.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import( /* webpackChunkName: "home" */ '../views/Payment.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import( /* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach((to, from, next) => {
  //window.scrollTo(0, 0)
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
