import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ActivityIcon,YoutubeIcon,ChevronLeftIcon,FacebookIcon,UserIcon,ArchiveIcon,HomeIcon,MapIcon,AwardIcon,PhoneIcon,CalendarIcon,MailIcon} from 'vue-feather-icons'
import { BootstrapVue } from 'bootstrap-vue'
import VueLazyLoad from 'vue-lazyload'
import { firestorePlugin } from 'vuefire'


import './assets/scss/app.scss'
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";

library.add(faUserSecret)
Vue.use(BootstrapVue)
Vue.use(VueLazyLoad)


// firebase
import './firebase'

Vue.use(firestorePlugin);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ActivityIcon', ActivityIcon)
Vue.component('YoutubeIcon', YoutubeIcon)
Vue.component('ChevronLeftIcon', ChevronLeftIcon)
Vue.component('FacebookIcon', FacebookIcon)
Vue.component('UserIcon', UserIcon)
Vue.component('ArchiveIcon', ArchiveIcon)
Vue.component('HomeIcon', HomeIcon)
Vue.component('MapIcon', MapIcon)
Vue.component('AwardIcon', AwardIcon)
Vue.component('PhoneIcon', PhoneIcon)
Vue.component('CalendarIcon', CalendarIcon)
Vue.component('MailIcon', MailIcon)


Vue.config.productionTip = false

let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
})