

<template>
  <header>
    <section>
      <div class="btn btn-block btn-dark text-truncate rounded-0 py-2 d-none d-lg-block" style="z-index: 1000;" target="_blank">
    <strong>האתר בבניה.</strong>
    </div>
        <div class="container">
            <b-navbar toggleable="lg" variant="info" class="navbar navbar-expand-lg navbar-light bg-white">
              <b-navbar-brand class="navbar-brand" to="/">
                      <img alt="Image placeholder" src="../assets/images/logo.svg" id="navbar-logo">
              </b-navbar-brand>
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <!-- Collapse -->
              <b-collapse  id="nav-collapse" is-nav>
                  <b-navbar-nav class="ml-auto">
                      <b-nav-item-dropdown text="מוסדות">
                        <b-dropdown-item to="/synagogue">בית כנסת</b-dropdown-item>
                        <b-dropdown-item to="/talmod"> תלמוד תורה</b-dropdown-item>
                        <b-dropdown-item to="/yeshiva"> ישיבה לצעירים</b-dropdown-item>
                        <b-dropdown-item to="/midrasha">המדרשה לנשים</b-dropdown-item>
                        <b-dropdown-item to="/yeshiva2">ישיבה למתחזקים</b-dropdown-item>

                      </b-nav-item-dropdown>
                  
                      <b-nav-item target="_blank" href="https://www.youtube.com/channel/UCUOouQEqC_a8ZXSpxrWuJ-w/videos">שיעורים והרצאות</b-nav-item>
                  
                      <b-nav-item-dropdown text="פעילות קהילתית">
                        <b-dropdown-item to="/community">ארועים קהילתיים</b-dropdown-item>
                        <b-dropdown-item to="/holidays">חגים</b-dropdown-item>
                      </b-nav-item-dropdown>
                 
                      <b-nav-item to="/contactus">צור קשר</b-nav-item>
                 
                      
                  </b-navbar-nav>
                        <b-navbar-nav class="ml-auto">
                                      <!-- Button -->
                        <router-link class="navbar-btn btn btn-sm btn-primary d-none d-lg-inline-block ml-3" to="/joinus">שותפים לתורה</router-link>
                        <div class="d-lg-none text-center">
                     <router-link class="btn btn-block btn-sm btn-primary" to="/joinus">שותפים לתורה</router-link>
                </div>
                  </b-navbar-nav>

              </b-collapse>
            </b-navbar>
        </div>
    </section>
  </header>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>